/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { Button } from "antd";
import { useEffect, useState } from "react";

/**
 * Project components.
 */
import { CommunicationDirection } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { Icon } from "@/components/client/icon";
import { User } from "@/components/client/images";
import { useTwilioContext } from "@/components/client/twilio";
import { TimeUtility } from "@/components/common/time";

/**
 * Call control card properties.
 */
export type CallControlLiveCard = {
  data: CommunicationLog | null;
  /**
   * Callbacks for the call control card.
   */
  /**
   * Indicates that the call control card is visible.
   */
  visible?: boolean;
};

/**
 * Active call control card.
 * Includes a timer, client phone number, and call control buttons (mute, end call).
 */
export function CallControlLiveCard({
  data,
  visible = false,
}: CallControlLiveCard) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    calls: twilioCalls,
    getCall,
    isMuted,
    toggleMute,
  } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const [call, setCall] = useState<Call | null>(null);

  /**
   * Duration of the call in milliseconds
   */
  const [duration, setDuration] = useState(0);

  // const [muted, setMuted] = useState(false);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    if (!data?.dateStarted) {
      return;
    }

    const interval = setInterval(() => {
      setDuration(() => new Date().getTime() - data.dateStarted!.getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [data?.dateStarted]);

  /**
   * Links the Twilio call to this card if applicable.
   * This enables the hangup and mute buttons to work.
   */
  useEffect(() => {
    if (!data?.callSid) {
      setCall(null);
      return;
    }

    setCall(getCall({ callSid: data?.callSid }));
  }, [data?.callSid, getCall]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || !visible || !call) {
    return null;
  }

  return (
    <div className="flex h-[276px] animate-slide-left flex-col items-center justify-center gap-4 self-stretch bg-white p-[30px]">
      <div className="flex h-40 flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold leading-[17.50px] text-slate-600">
          Live {TimeUtility.formatDuration({ duration })}
        </div>
        <div className="relative h-16 w-16 rounded-[100px] bg-slate-100">
          <div className="absolute left-0 top-0 h-16 w-16 overflow-hidden rounded-full bg-slate-100">
            <User className="absolute left-[4px] top-[8px] h-14 w-14" />
          </div>
        </div>
        {/* <div className="self-stretch h-9 flex-col justify-start items-start flex">
          <div className="self-stretch text-center text-slate-600 text-sm  font-semibold leading-[17.50px]">
            [8210] Elle Cheong/ Timothy Cheong (P4 2024), Daisy Cheong (S1,
            2024)
          </div>
        </div> */}
        <div className="self-stretch text-center text-sm leading-[17.50px] text-gray-400">
          {data?.direction === CommunicationDirection.INBOUND
            ? data.from
            : data.to}
        </div>
      </div>
      <div className="inline-flex h-10 items-start justify-start gap-2">
        <Button
          icon={
            <Icon
              src={
                isMuted({
                  callSid: data.callSid,
                })
                  ? "microphone-mute"
                  : "microphone"
              }
            />
          }
          onClick={(event) => {
            event.stopPropagation();

            toggleMute({ callSid: data.callSid });
          }}
          shape="circle"
          style={{
            height: 40,
            width: 40,
          }}
          type="default"
        />
        <Button
          icon={<Icon className="!text-white" src="phone-down" />}
          onClick={() => {
            call?.disconnect();

            if (call) {
              setCall(null);
            }
          }}
          shape="circle"
          style={{
            backgroundColor: "var(--semantic-red)",
            height: 40,
            width: 40,
          }}
          type="primary"
        />
      </div>
    </div>
  );
}

/** Props for the CallInformationCardField component. */
type CallInformationCardFieldProps = {
  /**
   * Label for the field.
   */
  label: string;
  /**
   * Value for the field.
   */
  value?: string | number;
  /**
   * Optional classname for hte container.
   */
  className?: string;
};

/**
 * A simple reusable component for
 * rendering a field with a "label" and a "value"
 *
 * @example
 * **Date:** April 14, 2024
 */
export function CallInformationCardField(props: CallInformationCardFieldProps) {
  return (
    <div className={props.className}>
      <span className="text-sm font-semibold text-tpl-navy">
        {props.label}:
      </span>
      <span className="text-sm text-tpl-navy">{" " + props.value}</span>
    </div>
  );
}

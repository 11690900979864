/**
 * Third-party libraries.
 */
import { TimeUtility } from "@/components/common/time";
import { Button } from "antd";
import { User } from "../images";

/**
 * Call complete card properties.
 */
type CallCompleteCardProps = {
  /**
   * Callbacks for the call complete card.
   */
  callback: {
    onCompleteCall: (args: {
      /**
       * Twilio call SID.
       */
      callSid: string;
    }) => void;
  };
  /**
   * Data for rendering the call complete card.
   */
  data: {
    /**
     * Twilio call SID.
     */
    callSid: string;
    /**
     * Duration of the call.
     */
    duration: number;
    /**
     * Name of the client.
     */
    clientName?: string;
    /**
     * Phone number of the client.
     */
    clientPhoneNumber: string;
  };
  /**
   * Disables the "complete" button.
   */
  disabled?: boolean;
  /**
   * Displays a loading spinner on the "complete" button.
   */
  loading?: boolean;
  /**
   * A flag to indicate if the call complete card is visible.
   */
  visible?: boolean;
};

/**
 * A card which allows an agent to complete a call in "wrap up" status.
 * Display an avatar, the client name phone number, and a button to complete the call.
 */
export function CallCompleteCard({
  callback,
  data,
  disabled,
  loading,
  visible,
}: CallCompleteCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div className="inline-flex w-full animate-slide-left flex-col items-center justify-center gap-4 bg-white p-7">
      <div className="flex h-40 flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold leading-[17.50px] text-slate-600">
          Wrap Up {TimeUtility.formatDuration({ duration: data.duration })}
        </div>
        <div className="relative h-16 w-16 rounded-[100px] bg-slate-100">
          <div className="absolute left-0 top-0 h-16 w-16 overflow-hidden rounded-full bg-slate-100">
            <User className="absolute left-[4px] top-[8px] h-14 w-14" />
          </div>
        </div>
        {data.clientName?.length && (
          <div className="flex h-9 flex-col items-start justify-start self-stretch">
            <div className="self-stretch text-center text-sm font-semibold leading-[17.50px] text-slate-600">
              {data.clientName}
            </div>
          </div>
        )}
        <div className="self-stretch text-center text-sm leading-[17.50px] text-gray-400">
          {data.clientPhoneNumber}
        </div>
      </div>
      <Button
        disabled={disabled}
        loading={loading}
        onClick={() => callback.onCompleteCall({ callSid: data.callSid })}
        type="primary"
      >
        Complete Call
      </Button>
    </div>
  );
}

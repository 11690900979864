"use client";

/**
 * Third-party libraries.
 */
// import { FilterOutlined as FilterOutlinedIcon } from "@ant-design/icons";
import { Alert, Button } from "antd";

/**
 * Project components.
 */
import {
  CallCompleteCard,
  CallControlLiveCard,
  CallControlRingingCard,
  CallInformationCard,
  CallRoutingCard,
  CallSummaryCard,
} from "@/components/client/call";
import { CallPastInteractionsCard } from "@/components/client/call/call-past-interactions-card";
import { CallRecordingPlayerCard } from "@/components/client/call/call-recording-player-card";
import {
  CommunicationLog,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import CommunicationLogSelectALogPlaceholder from "@/components/client/communication-log/communication-log-select-a-log-placeholder";
import { useCallCompleteMutation } from "@/components/client/graphql";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";
import { useNetworkContext } from "@/components/client/network";
import { useTwilioContext } from "@/components/client/twilio";

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Communication Logs Active
    // =========================================================================
    communicationLogsActive,
    communicationLogsActiveLoading,
    // =========================================================================
    // Communication Logs Concluded
    // =========================================================================
    communicationLogsConcluded,
    communicationLogsConcludedLoading,
    communicationLogsConcludedFetchingMore,
    fetchMoreCommunicationLogsConcluded,
    hasMoreCommunicationLogsConcluded,
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog,
    selectedCommunicationLogLoading,
    setSelectedCommunicationLog,
  } = useCommunicationLogContext();

  const { connected, loading: networkLoading } = useNetworkContext();
  const { deviceRegistered, deviceRegistering } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [
    completeCall,
    { data: completeCallResponse, loading: callCompleting },
  ] = useCallCompleteMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <ApplicationDefaultProtectedPageLayout>
      <div className="flex h-full">
        <div className="grid h-full w-full grid-cols-12 grid-rows-1">
          {/* 2nd Panel (Communication Log) */}
          <div className="col-span-6 flex h-full flex-col lg:col-span-4">
            {((!networkLoading && !connected) ||
              (!deviceRegistering && !deviceRegistered)) && (
              <Alert
                className="w-full"
                message="Disconnected: Please reload the page."
                type="error"
                showIcon
                action={
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                    size="small"
                    type="primary"
                  >
                    Reload
                  </Button>
                }
              />
            )}
            <CommunicationLog
              callback={{
                onMaxScroll: fetchMoreCommunicationLogsConcluded,
                onSearch: async () => {},
              }}
              classNames="!h-auto"
              data={communicationLogsActive}
              hideListStatusIndicator
              loading={communicationLogsActiveLoading}
              onLogClick={({ communicationLog }) => {
                setSelectedCommunicationLog(communicationLog);
              }}
            />
            {/* <Divider className="!m-0" /> */}
            {/* <div className="w-full h-16 p-4 bg-white border-b border-slate-200 flex-col justify-center items-start gap-2 inline-flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <Input.Search
                    allowClear
                    className="bg-violet-50 rounded border border-slate-200"
                    enterButton
                    // loading
                    placeholder="Search"
                    onSearch={(value) => {
                      console.log("Search", value);
                    }}
                    variant="filled"
                  />
                </div>
                <Button icon={<FilterOutlined />} type="text" />
              </div>
            </div> */}
            <CommunicationLog
              callback={{
                onMaxScroll: fetchMoreCommunicationLogsConcluded,
                onSearch: async () => {},
              }}
              classNames="flex-grow !h-auto !min-h-[100px]"
              data={communicationLogsConcluded}
              hasMoreData={hasMoreCommunicationLogsConcluded}
              loading={communicationLogsConcludedLoading}
              loadingMoreData={communicationLogsConcludedFetchingMore}
              onLogClick={({ communicationLog }) => {
                setSelectedCommunicationLog(communicationLog);
              }}
            />
          </div>

          {/* 3rd Panel (Placeholder) */}
          <div
            className={`col-span-6 inline-flex h-full w-full flex-col items-center justify-center bg-neutral-surface-grey lg:col-span-8 ${
              !selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <CommunicationLogSelectALogPlaceholder />
          </div>

          {/* 3rd Panel (Current Call Info) */}
          <div
            className={`col-span-6 lg:col-span-4 ${
              !!selectedCommunicationLog ? "" : "hidden"
            }`}
          >
            <div className="flex h-full w-full flex-col items-center justify-start gap-4 overflow-y-auto bg-[#fafbff] p-4">
              <CallCompleteCard
                callback={{
                  onCompleteCall: async () => {
                    if (!selectedCommunicationLog?.callSid) {
                      return;
                    }

                    await completeCall({
                      variables: {
                        input: {
                          callSid: selectedCommunicationLog.callSid,
                        },
                      },
                    });
                  },
                }}
                data={{
                  callSid: selectedCommunicationLog?.callSid!,
                  clientPhoneNumber:
                    selectedCommunicationLog?.clientPhoneNumber ?? "",
                  // Convert to milliseconds.
                  duration: selectedCommunicationLog?.duration
                    ? selectedCommunicationLog?.duration * 1000
                    : 0,
                }}
                disabled={selectedCommunicationLogLoading || callCompleting}
                loading={callCompleting}
                visible={
                  !!selectedCommunicationLog?.callSid &&
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
              <CallControlLiveCard
                data={selectedCommunicationLog}
                visible={
                  selectedCommunicationLog?.status ===
                  CommunicationLogStatus.ONGOING
                }
              />
              <CallControlRingingCard data={selectedCommunicationLog} />
              <CallInformationCard
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.RINGING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
              <CallRecordingPlayerCard
                loading={selectedCommunicationLogLoading}
                visible={
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.WRAPPING_UP ||
                  selectedCommunicationLog?.status ===
                    CommunicationLogStatus.COMPLETED
                }
              />
              <CallSummaryCard
                data={{
                  callSid: selectedCommunicationLog?.callSid!,
                  summary: selectedCommunicationLog?.summary,
                }}
                disabled={
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
                visible={
                  !!selectedCommunicationLog?.callSid &&
                  (selectedCommunicationLog?.status ===
                    CommunicationLogStatus.ONGOING ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.WRAPPING_UP ||
                    selectedCommunicationLog?.status ===
                      CommunicationLogStatus.COMPLETED)
                }
              />
              <CallRoutingCard
                loading={selectedCommunicationLogLoading}
                routings={selectedCommunicationLog?.routings ?? []}
                visible={
                  !!selectedCommunicationLog &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.RINGING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.ONGOING &&
                  selectedCommunicationLog?.status !==
                    CommunicationLogStatus.WRAPPING_UP
                }
              />
            </div>
          </div>

          {/* 4th Panel (Other Call Info) */}
          <div
            className={`hidden border-l lg:col-span-4 ${
              // Hide when nothing is selected.
              !!selectedCommunicationLog ? "lg:block" : "hidden"
            }`}
          >
            <div className="flex h-full w-full flex-col items-center justify-start gap-4 overflow-y-auto bg-[#fafbff] p-4">
              <CallPastInteractionsCard
                // Always visible.
                visible={!!selectedCommunicationLog}
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationDefaultProtectedPageLayout>
  );
}

export default HomePage;

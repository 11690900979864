"use client";

/**
 * Third-party libraries.
 */
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
const ReactQuill = dynamic(() => import("react-quill"), {
  ssr: false,
});

import "./rich-text-renderer.css";

/** Props for the RichTextRender. */
type RichTextRendererProps = {
  /** Optional classname. */
  className?: string;
  /** Content for the rich text component. */
  content?: string;
};

/**
 * Renders a read-only RichText content (from React Quill). It is not editable.
 *
 * This component overrides the styles that ship with React Quill that make
 * it difficult to customize.
 */
export function RichTextRenderer({
  className,
  content,
}: RichTextRendererProps) {
  return (
    <ReactQuill
      className={`rich-text-renderer ${className}`} // check rich-text-renderer.css for the overrides.
      placeholder={"No call summary"}
      readOnly={true}
      tabIndex={1}
      theme="bubble"
      value={content}
    />
  );
}
